import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import swal from 'sweetalert';
import useLongPress from './extra/useLongPress';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import Countdown from './extra/Countdown';
import KYCStatus from './component/kyc/KYCStatus';
import SelfieCamera from './component/wallet/SelfieCamera';
import LeftNavSwiper from './component/swipe/LeftNavSwiper';
import { Sidebar } from 'primereact/sidebar';

const Wallet = (props) => {

    const history = useHistory()
    const location = useLocation()
    const { dialog } = useParams();

    // if (props.userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';
    // const [activation_code, setActivationCode] = useState('');
    const [inputvalue, setInputValue] = useState({
        "user_id": localStorage.getItem('user_id'),
        "otp_code": "",
        "payout_method": "",
        "account_name": "",
        "account_number": "",
        "amount": 0
      });
    // console.log(inputvalue)
    const [OpenActivateForm, setOpenActivateForm] = useState(false);
    const [Copied, setCopied] = useState({ id: '', status: false });
    const [payoutForm, setPayoutForm] = useState(false);
    const [sendFundForm, setSendFundForm] = useState(false);

    const [payoutlist, setPayoutList] = useState([])
    const [balance, setBalance] = useState(props.userInfo.balance)
    const [totalpayout, setTotalPayout] = useState(0)

    const [insufficientalert, setInsufficientAlert] = useState(false);
    const [incorrectpassword, setIncorrectPassword] = useState(false);
    const [accountName, setAccountName] = useState(false);
    const [accountNumber, setAccountNumber] = useState(false);
    const [amountRequest, setAmountRequest] = useState(false);
    const [otpcode, setOTPCode] = useState('');
    const [password, setPassword] = useState(false);
    const [payout_method, setPayoutMethod] = useState('');
    const [topupsidebar, setTopupSidebar] = useState({"open": false});


    const [resendAction, setResendAction] = useState(false);
    const [time, setTime] = useState(null);

    const [usdRate, setUSDRate] = useState(0);

    useMemo(async () => {
        // var formData = new FormData();
        // formData.append("admin_dashboard", 1);
        await axios({
            method: "GET",
            url: 'https://api.exchangerate-api.com/v4/latest/USD',
            // data: formData,
        }).then((res) => {
            console.log(res.data.rates.PHP)
            setUSDRate(res.data.rates.PHP)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    useMemo(async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV, location.key]);

    const payout_list = async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }

    const [SendFundInput, setSendFundInput] = useState({ user_id_to: null, amount: '' });

    const SendFund = async () => {

        if (SendFundInput.user_id_to != '' && SendFundInput.amount != '') {
            if (Number(SendFundInput.amount) != 0) {
                if (SendFundInput.user_id_to != localStorage.getItem('user_id')) {
                    var formData = new FormData();
                    formData.append("sendFund", 1);
                    formData.append("user_id", localStorage.getItem('user_id'));
                    formData.append("user_id_to", SendFundInput.user_id_to);
                    formData.append("amount", SendFundInput.amount);
                    formData.append("usdRate", usdRate);
                    await axios({
                        method: "post",
                        url: ENV.DOMAIN + 'process.php',
                        data: formData,
                    }).then((res) => {
                        if (res.data == 'ok') {
                            swal({
                                // title: "Done!",
                                text: "Successfully Sent",
                                icon: "success",
                                timer: 1000,
                                button: false,
                            }).then(() => {
                                window.location.assign('/wallet')
                            });
                        } else {
                            swal({
                                // title: "Done!",
                                text: "you have insufficient balance",
                                icon: "error",
                                timer: 2000,
                                button: false,
                            });
                        }
                        // setPayoutList(res.data)
                        // alert(JSON.stringify(res.data));
                    }).catch((err) => {

                    });
                } else {
                    swal({
                        // title: "Done!",
                        text: "Invalid",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    });
                }
            } else {

            }
        } else {

        }
    }


    const btnstatus = (item) => {
        if (item.status == 'deducted') {
            return (<span style={{ color: "red", fontSize: 10 }}>Deducted</span>);
        } else if (item.status == 'cancelled') {
            return (<span style={{ color: "red", fontSize: 10 }}>Cancelled</span>);
        } else if (item.status == 'declined') {
            return (<span style={{ color: "red", fontSize: 10 }}>Declined</span>);
        } else if (item.status == 'received') {
            return (<span style={{ color: "blue", fontSize: 10 }}>Recevied Fund</span>);
        } else if (item.status == 'transferred') {
            return (<span style={{ color: "blue", fontSize: 10 }}>Sent Fund</span>);
        } else if (item.status == 'approved') {
            return (<span style={{ color: "green", fontSize: 10 }}>Approved</span>);
        } else if (item.status == 'weekly_bonus') {
            return (<span style={{ color: "green", fontSize: 10 }}>Weekly Bonus</span>);
        } else if (item.status == 'topup') {
            return (<span style={{ color: "green", fontSize: 10 }}>Top-up</span>);
        } else {
            return (<a onClick={() => CancelRequest(item, 'cancelled')} style={{ width: 100, fontSize: 10, color: "red", textDecoration: "underline" }}>Cancel Payout</a>);
        }
    }

    const Status = (item) => {
        if (item.status == 'pending') {
            return item.status
        }
    }


    const CancelRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("cancel_payout_request", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("id", item.id);
        formData.append("amount", item.amount);
        formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setBalance(res.data[0].balance)
            payout_list()
        }).catch((err) => {

        });

    }

    const sendRequest = async () => {
        if (String(inputvalue.account_name) != '' && inputvalue.account_number != '' && String(inputvalue.payout_method) != '' && Number(inputvalue.amount) != 0 && props.userInfo.mobile != '' && inputvalue.otp_code != '') {
            if (!inputvalue.selfie_image) {
                swal({
                    // title: "Done!",
                    text: "Please take a picture Selfie",
                    icon: "info",
                    timer: 1000,
                    button: false,
                });
                return;
            }
            // return;

            var formData = new FormData();
            formData.append("payout_request", 1);
            formData.append("inputvalue", JSON.stringify(inputvalue));

            // formData.append("user_id", localStorage.getItem('user_id'));
            // formData.append("payout_method", String(inputvalue.payout_method));
            // formData.append("account_name", String(inputvalue.account_name));
            // formData.append("account_number", inputvalue.account_number);
            // formData.append("amount", Number(inputvalue.amount));
            // formData.append("mobile", props.userInfo.mobile);
            // formData.append("otpcode", inputvalue.otp_code);

            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                if (res.data[0].status == 'success') {
                    setInsufficientAlert(false)
                    setBalance(res.data[0].balance)
                    payout_list()
                    setPayoutForm(false)
                    setOTPCode('')
                    window.location.assign('/wallet')
                }
                else if (res.data[0].status == 'insufficient') {
                    setInsufficientAlert(true)
                }
                else if (res.data[0].status == 'expire_otp') {
                    swal({
                        text: "Expite OTP Code",
                        icon: "error",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        setOTPCode('')
                    });
                }
            }).catch((err) => {

            });
        } else {
            // alert('dsf')
        }

    }

    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////

    const onLongPress = () => {
        console.log('longpress is triggered');
    };

    const onClick = () => {
        console.log('click is triggered')
    }

    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };
    const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);
    // <button {...longPressEvent}>use  Loooong  Press</button>

    // useEffect(()=>{

    //     history.pushState(null, null, window.location.href);
    //     window.onpopstate = function () {
    //         history.go(1);
    //     };
    // },[history])



    const [fix, setFix] = useState(true)

    function setFixed() {
        if (window.scrollY >= 58) {
            // console.log(false)
            setFix(false)
        } else {
            setFix(true)
            // console.log(false)
        }
        // console.log(window.scrollY)
    }

    useMemo(() => {
        window.addEventListener('scroll', setFixed)
    }, [])


    const sendOTP = async () => {
        setResendAction(true)
        var formData = new FormData();
        formData.append("send_otp", 1);
        formData.append("user_id", localStorage.getItem('user_id'));
        formData.append("number", props.userInfo.mobile);
        formData.append("type", "payout");
        if (props.userInfo.mobile != '') {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                // alert(res.data)
                // setTime(null)
                // swal({
                //     title: "Done!",
                //     text: "Successfull added contact number",
                //     icon: "success",
                //     timer: 1000,
                //     button: false,
                // }).then(() => {
                // setResendAction(false)
                //     console.log(input)
                // });
            });
        }
    }


    const HistoryPanel = (item) => {


        if (item?.status == 'approved') {
            return (
                <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>{item.payout_method} <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[payout]</span></div>
                        </div>
                        <div>
                            <span style={{ color: "red" }}>-${(Number(item.amount) + Number(item.fee)).toFixed(2)}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Amnt: ${item.amount}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Fee: ${item.fee}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            <small>Account Name</small>
                            <br />
                            {item.account_name}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            {
                                item.status == 'transferred' ?
                                    <small>Account ID</small>
                                    :
                                    <small>Account Number</small>
                            }
                            <br />
                            {item.account_number}
                        </div>
                    </div>
                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                        <div>{item.date_approved}</div>
                        <div>{btnstatus(item)}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'cancelled') {
            return (
                <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>{item.payout_method} <span style={{ color: "rgb(159 158 158)", fontSize: 12 }}>[payout]</span></div>
                        </div>
                        <div>
                            <span style={{ color: "red" }}>-${(Number(item.amount) + Number(item.fee)).toFixed(2)}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Amnt: ${item.amount}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Fee: ${item.fee}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            <small>Account Name</small>
                            <br />
                            {item.account_name}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            {
                                item.status == 'transferred' ?
                                    <small>Account ID</small>
                                    :
                                    <small>Account Number</small>
                            }
                            <br />
                            {item.account_number}
                        </div>
                    </div>
                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                        <div>{item.date_approved}</div>
                        <div>{btnstatus(item)}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'pending') {
            return (
                <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>{item.payout_method}</div>
                        </div>
                        <div>
                            <span style={{ color: "red" }}>-${(Number(item.amount) + Number(item.fee)).toFixed(2)}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Amnt: ${item.amount}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            Fee: ${item.fee}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            <small>Account Name</small>
                            <br />
                            {item.account_name}
                        </div>
                        <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                            {
                                item.status == 'transferred' ?
                                    <small>Account ID</small>
                                    :
                                    <small>Account Number</small>
                            }
                            <br />
                            {item.account_number}
                        </div>
                    </div>
                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                        <div>{item.date_approved}</div>
                        <div>{btnstatus(item)}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'deducted') {
            return (
                <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ fontSize: 12, fontWeight: 600 }}>Deduction</div>
                        </div>
                        <div>
                            <span style={{ color: "red" }}>-${(Number(item.amount) + Number(item.fee)).toFixed(2)}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>{item.description}</div>
                    </div>
                    <div>
                        <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
                    </div>
                </div>
            )
        } else if (item?.status == 'declined') {
            return (
                <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ fontSize: 12, fontWeight: 600 }}>Declined</div>
                        </div>
                        <div>
                            <span style={{ color: "red" }}>-${(Number(item.amount) + Number(item.fee)).toFixed(2)}</span>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>{item.description}</div>
                    </div>
                    <div>
                        <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
                    </div>
                </div>
            )
        }
        return;






        if (item.status === 'transferred') {
            if (item.from_user_id === localStorage.getItem('user_id')) {
                return (
                    <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div style={{ fontSize: 12, fontWeight: 600 }}>Transfer Wallet</div>
                            </div>
                            <div>
                                <span style={{ color: "red" }}>-${item.amount}</span>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>To:  {item.to_user_name} <span style={{ fontWeight: 600 }}>[{item.to_user_id}]</span> </div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>Ref:  {item.transfer_refid} </div>
                        </div>
                        <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
                        </div>
                    </div>
                )
            } else if (item.to_user_id === localStorage.getItem('user_id')) {
                return (
                    <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div style={{ fontSize: 12, fontWeight: 600 }}>Transfer Wallet</div>
                            </div>
                            <div>
                                <span style={{ color: "green" }}>+${item.amount}</span>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>From:  {item.from_user_name} <span style={{ fontWeight: 600 }}>[{item.from_user_id}]</span> </div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>Ref:  {item.transfer_refid} </div>
                        </div>
                        <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
                        </div>
                    </div>
                )
            }
        } else if (item.status === 'topup') {
            if (item.user_id === localStorage.getItem('user_id')) {
                return (
                    <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div style={{ fontSize: 12, fontWeight: 600 }}>Top-up</div>
                            </div>
                            <div>
                                <span style={{ color: "green" }}>+${item.amount}</span>
                            </div>
                        </div>
                        <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>Ref:  {item.topup_refid} </div>
                        </div>
                        <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
                        </div>
                    </div>
                )
            }
        } else if (item.status === 'activation') {
            if (item.user_id === localStorage.getItem('user_id')) {
                return (
                    <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                <div style={{ fontSize: 12, fontWeight: 600 }}>Activation</div>
                            </div>
                            <div>
                                <span style={{ color: "red" }}>-${item.amount}</span>
                            </div>
                        </div>
                        {/* <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>From:  {item.from_user_name} </div>
                        </div>   */}
                        <div>
                            <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
                        </div>
                    </div>
                )
            }
        } else {
            // if(item.user_id === localStorage.getItem('user_id')){
            // if(item?.status != 'weekly_bonus' && item?.status != 'monthly_bonus') {
            // if (item?.status == 'approved') {
            //     return (
            //         <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            //             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                     <div style={{ fontSize: 15, fontWeight: 600 }}>{item.payout_method} <span style={{color:"rgb(159 158 158)", fontSize:12}}>[payout]</span></div>
            //                 </div>
            //                 <div>
            //                     <span style={{color:"red"}}>-${(Number(item.amount)+Number(item.fee)).toFixed(2)}</span>
            //                 </div>
            //             </div>  
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
            //                     Amnt: ${item.amount}
            //                 </div>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
            //                     Fee: ${item.fee}
            //                 </div>
            //             </div>
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
            //                     <small>Account Name</small>
            //                     <br />
            //                     {item.account_name}
            //                 </div>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
            //                     {
            //                         item.status == 'transferred' ?
            //                         <small>Account ID</small>
            //                         :
            //                         <small>Account Number</small>
            //                     }
            //                     <br />
            //                     {item.account_number}
            //                 </div>
            //             </div>
            //             <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
            //                 <div>{item.date_approved}</div>
            //                 <div>{btnstatus(item)}</div>
            //             </div>                 
            //         </div>
            //     )
            // } else if (item?.status == 'cancelled') {
            //     return (
            //         <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            //             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                     <div style={{ fontSize: 15, fontWeight: 600 }}>{item.payout_method} <span style={{color:"rgb(159 158 158)", fontSize:12}}>[payout]</span></div>
            //                 </div>
            //                 <div>
            //                     <span style={{color:"red"}}>-${(Number(item.amount)+Number(item.fee)).toFixed(2)}</span>
            //                 </div>
            //             </div>  
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
            //                     Amnt: ${item.amount}
            //                 </div>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
            //                     Fee: ${item.fee}
            //                 </div>
            //             </div>
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
            //                     <small>Account Name</small>
            //                     <br />
            //                     {item.account_name}
            //                 </div>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
            //                     {
            //                         item.status == 'transferred' ?
            //                         <small>Account ID</small>
            //                         :
            //                         <small>Account Number</small>
            //                     }
            //                     <br />
            //                     {item.account_number}
            //                 </div>
            //             </div>
            //             <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
            //                 <div>{item.date_approved}</div>
            //                 <div>{btnstatus(item)}</div>
            //             </div>                 
            //         </div>
            //     )
            // } else if (item?.status == 'pending') {
            //     return (
            //         <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            //             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                     <div style={{ fontSize: 12, fontWeight: 600 }}>{item.payout_method}</div>
            //                 </div>
            //                 <div>
            //                     <span style={{color:"red"}}>-${(Number(item.amount)+Number(item.fee)).toFixed(2)}</span>
            //                 </div>
            //             </div>  
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
            //                     Amnt: ${item.amount}
            //                 </div>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>
            //                     Fee: ${item.fee}
            //                 </div>
            //             </div>
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
            //                     <small>Account Name</small>
            //                     <br />
            //                     {item.account_name}
            //                 </div>
            //                 <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
            //                     {
            //                         item.status == 'transferred' ?
            //                         <small>Account ID</small>
            //                         :
            //                         <small>Account Number</small>
            //                     }
            //                     <br />
            //                     {item.account_number}
            //                 </div>
            //             </div>
            //             <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
            //                 <div>{item.date_approved}</div>
            //                 <div>{btnstatus(item)}</div>
            //             </div>             
            //         </div>
            //     )
            // } else if (item?.status == 'deducted') {
            //     return (
            //         <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            //             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                     <div style={{ fontSize: 12, fontWeight: 600 }}>Deduction</div>
            //                 </div>
            //                 <div>
            //                     <span style={{color:"red"}}>-${(Number(item.amount)+Number(item.fee)).toFixed(2)}</span>
            //                 </div>
            //             </div>
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div>{item.description}</div>
            //             </div>
            //             <div>
            //                 <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
            //             </div>                
            //         </div>
            //     )
            // } else if (item?.status == 'declined') {
            //     return (
            //         <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            //             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                 <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            //                     <div style={{ fontSize: 12, fontWeight: 600 }}>Deduction</div>
            //                 </div>
            //                 <div>
            //                     <span style={{color:"red"}}>-${(Number(item.amount)+Number(item.fee)).toFixed(2)}</span>
            //                 </div>
            //             </div>
            //             <div style={{display:"flex", flexDirection:"row"}}>
            //                 <div>{item.description}</div>
            //             </div>
            //             <div>
            //                 <div style={{ fontSize: 12, fontWeight: "normal" }}>{item.date_approved}</div>
            //             </div>                
            //         </div>
            //     )
            // }
            // }
            // }
        }
    }



    const [leftright, setLeftRight] = useState('Left')
    const refArea = useRef()

    let touchArea = document.getElementById("touch-area");
    let mouseX, initialX = 0;
    let mouseY, initialY = 0;
    let isSwiped = false;

    let events = {
        mouse: {
            down: "mousedown",
            move: "mousemove",
            up: "mouseup",
        },
        touch: {
            down: "touchstart",
            move: "touchmove",
            up: "touchend",
        }
    }

    let deviceType = "";

    // Detect touch device

    const isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            deviceType = "touch";
            return true;
        } catch (error) {
            deviceType = "mouse";
            return false;
        }
    }

    isTouchDevice();

    // console.log(events[deviceType].down)

    let rectLeft = 0; //touchArea.getBoundingClientRect([0]).left;
    let rectTop = 0; //touchArea.getBoundingClientRect([0]).top;
    // useEffect(()=>{
    //     if (props.userInfo) {
    rectLeft = touchArea?.getBoundingClientRect().left;
    rectTop = touchArea?.getBoundingClientRect().top;
    //     }
    // }, [])

    const getXY = (e) => {
        mouseX = (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft;
        mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    }

    // isTouchDevice();

    // console.log(touchArea)


    touchArea?.addEventListener(events[deviceType].down, (event) => {
        isSwiped = true;
        getXY(event);
        initialX = mouseX;
        initialY = mouseY;
    });

    let diffX = 0;
    let diffY = 0;

    touchArea?.addEventListener(events[deviceType].move, (event) => {
        if (!isTouchDevice()) {
            event.preventDefault();
        }
        if (isSwiped) {
            getXY(event);
            diffX = mouseX - initialX;
            diffY = mouseY - initialY;
            if (Math.abs(diffY) > Math.abs(diffX)) {
                // console.log(diffY > 0 ? "Down" : "Up")
                // output.innerText = diffY > 0 ? "Down" : "Up"
            } else {
                // console.log(diffX > 0 ? "Right" : "Left")
                // if((diffY > 0 ? "Down" : "Up") !== "Down" || (diffY > 0 ? "Down" : "Up") !== "Up"){
                // setLeftRight(diffX > 0 ? "Right" : "Left")
                // }
                // output.innerText = diffX > 0 ? "Right" : "Left"
                if (leftright !== 'Right') {
                    if (Math.abs(diffX) <= 50) {
                        setLeftRight(diffX > 0 ? "Right" : "Left")
                    }
                }
            }
        }
    });

    touchArea?.addEventListener(events[deviceType].up, (event) => {
        isSwiped = false;
    });

    touchArea?.addEventListener("mouseleave", (event) => {
        isSwiped = false;
    });

    window.onload = () => {
        isSwiped = false;
    }

    // if (isSwiped) {
    //     window.scrollTo({ top: 0, behavior: 'smooth' });
    // }

    

    const Topup = () => {
        console.log(topupsidebar)
    }

    return (
        <div className="wrapper" ref={refArea} id='touch-area'>

            <LeftNavSwiper userInfo={props.userInfo} leftright={leftright} setLeftRight={setLeftRight} tab="wallet" />
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                <>
                    <div style={{ flex: 1, height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div onClick={()=>setLeftRight('Right')} style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span className='pi pi-bars'></span>
                            </div>
                        </div>
                        <div style={{ flex: 4, paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                            <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{ width: 45, height: 45 }} />
                            <div style={{ display: "flex", flexDirection: "column", color: "green", lineHeight: 1 }}>
                                <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute" }}>Mirabile</span>
                                <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7 }}>&</span>
                                <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12 }}>Proventus
                                    <span style={{ fontWeight: 600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5 }}>Co.</span>
                                </span>
                            </div>
                        </div>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ height: 40, width: 40, border: "1px solid #b5a54d", borderRadius: 15, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                <span className='pi pi-bell'></span>
                            </div>
                        </div>
                    </div>
                </>
                    :
                    <Header />
            }
            {/* /.navbar */}

            {/* ////// TOPUP ////// */}
            <Sidebar
                icons={
                    <div style={{width: "100%", fontWeight: "bolder"}}>Top-up</div>
                }
                visible={dialog === 'topup'? true : false} 
                blockScroll={true} 
                showCloseIcon={false} 
                position="bottom" 
                style={{ minHeight: 500, borderRadius: 0, backgroundColor: "#ddd" }}
                onHide={() => history.goBack()}
            >
                <div style={{flex: 1}}>
                    <div style={{padding: "10px 0px"}}>
                        <input type='number' name='amount' onChange={(e)=>setTopupSidebar(prev=>({...prev, [e.target.name]: e.target.value}))} style={{border: "none"}} placeholder='Amount' className='form-control' />
                    </div>
                    <div style={{padding: "10px 0px"}}>
                        <input name='reference_number' onChange={(e)=>setTopupSidebar(prev=>({...prev, [e.target.name]: e.target.value}))} style={{border: "none"}} placeholder='Reference Number' className='form-control' />
                    </div>
                    <div style={{padding: "10px 0px"}}>
                        <div style={{width: 150, height:200, backgroundColor: "#fff"}}>

                        </div>
                    </div>
                    <div style={{padding: "10px 0px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                        <div style={{flex: 1}}></div>
                        <div style={{flex: 1}}>
                            <button onClick={Topup} style={{width: 150, height: 50, borderRadius: 10, border: "none", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", backgroundColor: "#609af8", color: "#fff"}}>Top-up</button>
                        </div>
                    </div>
                </div>
            </Sidebar>
            {/* ////// PAYOUT ////// */}
            <Sidebar
                icons={
                    <div style={{width: "100%", "fontSize": 25, "fontWeight": 800}}>Payout</div>
                }
                visible={dialog === 'payout'? true : false} 
                blockScroll={true} 
                showCloseIcon={false} 
                position="bottom" 
                style={{ minHeight: "100vh", borderRadius: 0 }}
                onHide={() => history.goBack()}
            >
                {/* Payout Method */}
                {/* Account Name */}
                {/* Account Number */}
                {/* Enter Amount */}
                {/* Take a Selfie */}
                {/* OTP (Code) */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                    <div>
                        <label>Payout Method</label>
                        <select name="payout_method" className="form-control">
                            <option value>Select...</option>
                            <option value="GCash">GCash</option>
                            <option value="GoTyme">GoTyme</option>
                        </select>
                    </div>
                    <div><label>Account Name</label><input type="text" name="account_name" className="form-control" placeholder="Account Name" /></div>
                    <div><label>Account Number</label><input type="text" name="account_number" className="form-control" placeholder="Account Number" /></div>
                    <div><label>Enter Amount</label><input type="number" name="amount" className="form-control" placeholder={0.00} /></div>
                    <div>
                        <label>Take a Selfie</label>
                        <div style={{ width: 120, height: 170, borderRadius: 5, border: '1px solid rgb(221, 221, 221)', backgroundColor: 'rgb(255, 255, 255)' }} />
                    </div>
                    <div>
                        <label>OTP <span style={{ color: 'rgb(136, 136, 136)' }}>(Code)</span></label>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 5 }}>
                            <input type="number" name="otp_code" className="form-control" placeholder style={{ width: '100%', textAlign: 'center' }} defaultValue />
                            <div style={{ width: 200, textAlign: 'center' }}>
                                <div>63927****0610</div>
                                <div style={{ textDecoration: 'underline' }}>Send OTP</div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexGrow: 1, gap: 5 }}>
                        <button className="btn btn-primary btn-block">Request Payout</button>
                        <button onClick={() => history.goBack()} className="btn btn-danger">Cancel</button>
                    </div>
                </div>
            </Sidebar>
            {/* ////// PAYOUT ////// */}
            <Sidebar
                icons={
                    <div style={{width: "100%", "fontSize": 25, "fontWeight": 800}}>Convert</div>
                }
                visible={false} 
                blockScroll={true} 
                showCloseIcon={false} 
                position="bottom" 
                style={{ minHeight: "100vh", borderRadius: 0 }}
                onHide={() => history.goBack()}
            >

            </Sidebar>



            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px', marginBottom: 50 }}>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12" style={{ padding: 5 }}>
                                {/* <button {...longPressEvent}>use  Loooong  Press</button> */}


                                {/* <div style={{flex: 1, backgroundColor: "#fff", borderRadius: 10, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", padding: 10, boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)"}}>
                                    <div style={{flex: 2, display: "flex", flexDirection: "column"}}>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ddd"}}>
                                            <span style={{ fontSize: 13, fontWeight: 600 }}>Reward: ${balance ?? 0}</span>
                                            <span style={{ fontSize: 13, fontWeight: "normal", textDecoration: "underline" }}>Convert</span>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column"}}>
                                            <span style={{ fontSize: 18, fontWeight: 600 }}>Balance: ${balance ?? 0}</span>
                                        </div>
                                        <span style={{ fontSize: 15 }}>Total Payout: ${props.userInfo?.total_payout ?? 0}</span>
                                    </div>
                                    <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", gap: 10}}>
                                        <Link to={"/wallet/topup"}>
                                            <button className='btn btn-primary btn-sm'>Topup</button>
                                        </Link>
                                        <Link to={"/wallet/payout"}>
                                            <button className='btn btn-warning btn-sm'>Payout</button>
                                        </Link>                                        
                                    </div>
                                </div>

                                <hr /> */}

                                {
                                    !fix ?
                                        <div>
                                            <div style={{ position: "fixed", zIndex: 2, width: '100%', transition: 'width 5s, transform 2s', top: 0, left: 0, paddingLeft: 0, paddingRight: 0 }}>
                                                <div style={{ width: "100%", borderRadius: 0, borderBottom: "1px solid #ddd", display: "flex", flexDirection: "column", padding: 10, backgroundColor: "#fff", backgroundImage: "url(https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.revivedwater.eu%2F&psig=AOvVaw2VtGmCLGF8-9YRdBtzlDPr&ust=1699197739534000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKD-9_7SqoIDFQAAAAAdAAAAABAE)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat, repeat" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                                                        <span style={{ fontSize: 10 }}>UPCOMING EARNING: <span style={{ fontWeight: 600, fontSize: 12 }}>${props.userInfo?.upcoming_earning}</span></span>
                                                    </div>
                                                    <div style={{ fontSize: 25, fontWeight: 600 }}>Balance: ${balance ?? 0} </div>
                                                    {/* <span style={{color:"rgb(137 135 135)", fontSize:12}}>(PHP {Number(balance*usdRate).toFixed(2)})</span> */}
                                                    <div style={{ fontSize: 12, fontWeight: 500 }}>Total Payout: ${props.userInfo?.total_payout ?? 0}</div>
                                                    {
                                                        Number(props.userInfo?.pending_payout) != 0 ?
                                                            <div style={{ fontSize: 12, fontWeight: 500 }}>Pending Payout: ${props.userInfo?.pending_payout ?? 0}</div>
                                                            : null
                                                    }                                                    {
                                                        payoutForm ?
                                                            null
                                                            :
                                                            <>
                                                                {
                                                                    props.userInfo?.close_payout ?
                                                                        <>
                                                                            {
                                                                                balance == '0.00' ?
                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                                                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div> */}
                                                                                        <KYCStatus userInfo={props.userInfo}>
                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                        </KYCStatus>
                                                                                    </div>
                                                                                    :
                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                                                        {/* {
                                                                                            userInfo.sendFund_oprate == true ?
                                                                                            <div onClick={() => {
                                                                                                setSendFundForm(true)
                                                                                                setPayoutForm(false);
                                                                                            }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                            :
                                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                        }                                                                                         */}
                                                                                        {
                                                                                            props.userInfo.account_status == 1 ?
                                                                                                <KYCStatus userInfo={props.userInfo}>
                                                                                                    <div onClick={() => {
                                                                                                        setSendFundForm(false)
                                                                                                        setPayoutForm(true);
                                                                                                    }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                                </KYCStatus>
                                                                                                :
                                                                                                <KYCStatus userInfo={props.userInfo}>
                                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                                </KYCStatus>
                                                                                        }
                                                                                    </div>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                                            {/* {
                                                                                userInfo.sendFund_oprate == true ?
                                                                                <>
                                                                                {
                                                                                    balance == '0.00' ?
                                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                    :
                                                                                    <div onClick={() => {
                                                                                        setSendFundForm(true);
                                                                                        setPayoutForm(false);
                                                                                    }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                }
                                                                                </>
                                                                                :
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                            } */}
                                                                            <KYCStatus userInfo={props.userInfo}>
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                            </KYCStatus>
                                                                        </div>
                                                                }
                                                            </>
                                                    }


                                                </div>
                                            </div>
                                            <div style={{ height: 119, backgroundColor: "#fff" }}></div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ width: "100%", borderRadius: 10, border: "1px solid #000", display: "flex", flexDirection: "column", padding: 10, backgroundColor: "#fff", backgroundImage: "url(https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.revivedwater.eu%2F&psig=AOvVaw2VtGmCLGF8-9YRdBtzlDPr&ust=1699197739534000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCKD-9_7SqoIDFQAAAAAdAAAAABAE)", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat, repeat" }}>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }} >
                                                    <span style={{ fontSize: 10 }}>UPCOMING EARNING: <span style={{ fontWeight: 600, fontSize: 12 }}>${props.userInfo?.upcoming_earning}</span></span>
                                                </div>
                                                <div style={{ fontSize: 25, fontWeight: 600 }}>Balance: ${balance ?? 0} </div>
                                                {/* <span style={{color:"rgb(137 135 135)", fontSize:12}}>(PHP {Number(balance*usdRate).toFixed(2)})</span> */}
                                                <div style={{ fontSize: 12, fontWeight: 500 }}>Total Payout: ${props.userInfo?.total_payout ?? 0}</div>
                                                {
                                                    Number(props.userInfo?.pending_payout) != 0 ?
                                                        <div style={{ fontSize: 12, fontWeight: 500 }}>Pending Payout: ${props.userInfo?.pending_payout ?? 0}</div>
                                                        : null
                                                }
                                                {
                                                    sendFundForm || payoutForm ?
                                                        null
                                                        :
                                                        <>
                                                            {
                                                                props.userInfo?.close_payout ?
                                                                    <>
                                                                        {
                                                                            balance == '0.00' ?
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                                                    {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div> */}
                                                                                    <KYCStatus userInfo={props.userInfo}>
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                    </KYCStatus>
                                                                                </div>
                                                                                :
                                                                                // <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                                                //     <div onClick={() => setSendFundForm(true)} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send</div>
                                                                                //     <div onClick={() => setPayoutForm(true)} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                // </div>
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                                                    {/* {
                                                                                        userInfo.sendFund_oprate == true ?
                                                                                        <div onClick={() => {
                                                                                            setSendFundForm(true);
                                                                                            setPayoutForm(false);
                                                                                        }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                        :
                                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                    } */}
                                                                                    {
                                                                                        props.userInfo.account_status == 1 ?
                                                                                            <KYCStatus userInfo={props.userInfo}>
                                                                                                <div onClick={() => {
                                                                                                    setSendFundForm(false);
                                                                                                    setPayoutForm(true);
                                                                                                }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                            </KYCStatus>
                                                                                            :
                                                                                            <KYCStatus userInfo={props.userInfo}>
                                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                                            </KYCStatus>
                                                                                    }

                                                                                    {/* <div onClick={() => {
                                                                                        setSendFundForm(false);
                                                                                        setPayoutForm(true);
                                                                                    }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div> */}
                                                                                </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: 10 }}>
                                                                        {/* {
                                                                            userInfo.sendFund_oprate == true ?
                                                                            <>
                                                                            {
                                                                                balance == '0.00' ? 
                                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                                :
                                                                                <div onClick={() => {
                                                                                    setSendFundForm(true);
                                                                                    setPayoutForm(false);
                                                                                }} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                            }
                                                                            </>
                                                                            :
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Send Wallet</div>
                                                                        } */}
                                                                        <KYCStatus userInfo={props.userInfo}>
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", alignSelf: "flex-end", backgroundColor: "#ddd", paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 30 }}>Payout</div>
                                                                        </KYCStatus>
                                                                    </div>
                                                            }
                                                        </>
                                                }

                                            </div>
                                        </div>
                                }
                                {/* <div style={{paddingTop: 10, paddingBottom: 10}}>
                                    <KYCStatus userInfo={props.userInfo} />
                                </div> */}
                                {/* <div style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                                        <span style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>Link Bank <span style={{fontSize:12, color:"red"}}>(soon)</span></span> <span className='pi pi-credit-card'></span>
                                    </div>
                                <hr /> */}
                                {/* {
                                    sendFundForm ?
                                    <></>
                                    :
                                    <></>
                                } */}
                                {
                                    sendFundForm ?
                                        <>
                                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                                <div>
                                                    <label>Amount:</label>
                                                    <input type='number' name='amount' onChange={(e) => setSendFundInput({ ...SendFundInput, [e.target.name]: e.target.value })} value={SendFundInput.amount} style={{ height: 40, width: "100%", fontSize: 25, padding: 5, outline: "none" }} placeholder='0.00' />
                                                </div>
                                                <div>
                                                    <label>Send to:</label>
                                                    <input type='number' name='user_id_to' onChange={(e) => setSendFundInput({ ...SendFundInput, [e.target.name]: e.target.value })} value={SendFundInput.user_id_to} style={{ height: 40, width: "100%", fontSize: 25, padding: 5, outline: "none" }} placeholder='Account ID' />
                                                </div>
                                            </div>
                                            <div style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>

                                                <div onClick={() => SendFund()} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", alignSelf: "flex-end", backgroundColor: "blue", fontWeight: 600, paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 40, width: 100 }}>Send</div>

                                                <div onClick={() => {
                                                    setSendFundForm(false);
                                                    setPayoutForm(false);
                                                    setSendFundInput({ user_id_to: '', amount: '' });
                                                }} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", alignSelf: "flex-end", backgroundColor: "red", fontWeight: 600, paddingLeft: 10, paddingRight: 10, color: "#fff", borderRadius: 5, height: 40, width: 100 }}>Cancel</div>

                                            </div>
                                        </>
                                        :
                                        <></>
                                }

                                {
                                    payoutForm ?
                                        <>
                                            <div><span style={{ fontSize: 25, fontWeight: "800" }}>Available Payout</span></div>
                                            {/* <div><span style={{ fontSize: 25, fontWeight: "800" }}>GCash Account</span></div> */}
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><span>Earning Bal:</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                </svg>
                                                <span style={{ fontSize: 25, fontWeight: "800" }}>{Number(props.userInfo?.balance_to_payout).toFixed(2)}</span></div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                <div>
                                                {/* setInputValue */}
                                                    <label>Payout Method</label>
                                                    <select name='payout_method' onChange={(e) => setInputValue(prev => ({...prev, [e.target.name]:e.target.value}))} className="form-control">
                                                    {/* setPayoutMethod(e.target.value) */}
                                                        <option value={""}>Select...</option>
                                                        <option value={"GCash"}>GCash</option>
                                                        <option value={"GoTyme"}>GoTyme</option>
                                                        {/* <option value={"BDO"}>BDO</option>
                                                        <option value={"BPI"}>BPI</option>
                                                        <option value={"EastWest"}>EastWest</option>
                                                        <option value={"RCBC"}>RCBC</option>
                                                        <option value={"Metrobank"}>Metrobank</option>
                                                        <option value={"PNB"}>PNB</option> */}
                                                    </select>
                                                    {/* <input type="text" onChange={(e) => setAccountName(e.target.value)} placeholder="" /> */}
                                                </div>
                                                <div>
                                                    <label>Account Name</label>
                                                    <input type="text" name='account_name' onChange={(e) => setInputValue(prev => ({...prev, [e.target.name]:e.target.value}))} className="form-control" placeholder="Account Name" />
                                                    {/* //setAccountName(e.target.value) */}
                                                </div>
                                                <div>
                                                    <label>Account Number</label>
                                                    <input type="text" name='account_number' onChange={(e) => setInputValue(prev => ({...prev, [e.target.name]:e.target.value}))} className="form-control" placeholder="Account Number" />
                                                </div>
                                                <div>
                                                    <label>Enter Amount</label>
                                                    <input type="number" name='amount' onChange={(e) => setInputValue(prev => ({...prev, [e.target.name]:e.target.value}))} className="form-control" placeholder="0.00" />
                                                </div>
                                                <div>
                                                    <label>Take a Selfie</label>
                                                    <SelfieCamera userInfo={props.userInfo} inputvalue={inputvalue} setInputValue={setInputValue} />
                                                </div>
                                                {
                                                    insufficientalert ?
                                                        <div style={{ color: "red" }}>Insufficient</div>
                                                        :
                                                        null
                                                }
                                                {/* <div>
                                                    <label>Enter Password</label>
                                                    <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="" />
                                                </div> */}
                                                <div>
                                                    <label>OTP <span style={{ color: "#888" }}>(Code)</span></label>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                                                        <input type="number" name='otp_code' onChange={(e) => setInputValue(prev => ({...prev, [e.target.name]:e.target.value}))} value={inputvalue.otp_code} className="form-control" placeholder="" style={{ width: "100%", textAlign: "center" }} />
                                                        {
                                                            resendAction ?
                                                                <div style={{ width: 200, textAlign: "center" }}>
                                                                    <div>{props.userInfo.telephone}</div>
                                                                    <Countdown second={119} setResendAction={setResendAction} setTime={setTime} />
                                                                </div>
                                                                :
                                                                <div style={{ width: 200, textAlign: "center" }}>
                                                                    <div>{props.userInfo.telephone}</div>
                                                                    <div onClick={() => sendOTP()} style={{ textDecoration: "underline" }}>Send OTP</div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    incorrectpassword ?
                                                        <div style={{ color: "red" }}>Incorrect Password</div>
                                                        :
                                                        null
                                                }
                                                <br />
                                                <div style={{ display: "flex", flexGrow: 1, gap: 5 }}>
                                                    <button className="btn btn-primary btn-block" onClick={() => sendRequest()}>Request Payout</button>
                                                    <button onClick={() => setPayoutForm(false)} className="btn btn-danger">Cancel</button>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                                }

                                {
                                    sendFundForm || payoutForm ?
                                        <></>
                                        :
                                        <>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                                {
                                                    payoutlist?.map((item) => {
                                                        return HistoryPanel(item)
                                                    })
                                                }
                                            </div>
                                        </>
                                }


                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/dashboard')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20 }}>
                        <i className='pi pi-microsoft' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{ position: "absolute", color: "#fff", padding: 3, top: 2, right: 10, backgroundColor: "red", fontSize: 8, borderRadius: 100 }}>Coming</span>
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Shop</span>
                    </div>
                    <div style={{ color: "#fff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#71a3f5", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{ fontSize: 12, color: "#fff" }}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Profile</span>
                    </div>
                </div> */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default Wallet;
