import { Sidebar } from 'primereact/sidebar'
import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import ENV from '../../ENV'

const LeftNavSwiper = (props) => {



    const Logout = () => {
        localStorage.setItem('login_access', false)
        localStorage.removeItem('username')
        window.location.replace('/login')
    }


    return (
        <Sidebar
            icons={
                <div style={{ flex: 1 }}>
                    <div style={{ flex: 1, borderRadius: 15, border: "1px solid #ddd", boxShadow: "0 .5rem 1rem rgba(0, 0, 0, .15)", padding: 10 }}>
                        <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ borderRadius: 100, border: "1px solid #ddd", width: 80, height: 80 }}>
                                <img className="img-circle elevation-2" src={props.userInfo?.image != null ? ENV.DOMAIN + props.userInfo?.image : "../dist/img/user1-128x128.jpg"} style={{ width: 80, height: 80 }} />
                            </div>
                        </div>
                        <span style={{ fontSize: 15, fontWeight: "600" }}>{props.userInfo?.fullname != null ? props.userInfo?.fullname : 'Loading...'}</span>
                        <div>{props.userInfo?.username != null ? `(${props.userInfo?.username})` : 'Loading...'}</div>
                        <span style={{ borderRadius: 10, border: "1px solid #ddd", padding: "0px 10px", fontSize: 12, minWidth: 100 }}>
                            {
                                props.userInfo?.rank_status === 'GRANDMASTER' ?
                                    <span>GRANDMASTER</span>
                                    : props.userInfo?.rank_status === 'MASTER' ?
                                        <span>MASTER</span>
                                        : props.userInfo?.rank_status === 'ELITE' ?
                                            <span>ELITE</span>
                                            : props.userInfo?.rank_status === 'ROOKIE' ?
                                                <span>ROOKIE</span>
                                                : props.userInfo?.rank_status === 'TYRO' ?
                                                    <span>TYRO</span>
                                                    : <span>UNRANKED</span>
                            }
                        </span>
                    </div>
                </div>
            }
            visible={props.leftright === 'Right' ? true : false} blockScroll={true} showCloseIcon={false} position="left" style={{ minHeight: 500, width: 250, borderRadius: 0 }}
            onHide={() => props.setLeftRight('Left')}
        >
            <div>
                <Link to="/dashboard">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'dashboard' ? "#f5f3eb" : "#fff" }}>
                        <span>Dashboard</span>
                    </div>
                </Link>
                <Link to="/wallet">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'wallet' ? "#f5f3eb" : "#fff" }}>
                        <span>My Wallet</span>
                    </div>
                </Link>
                {/* <div style={{ padding: 8 }}>
                    <span>Credit Points</span>
                </div> */}
                {/* /////////////////////////////////////// */}
                <hr />
                {/* /////////////////////////////////////// */}
                {/* <div style={{ padding: 8 }}>
                    <span style={{ fontSize: 18, fontWeight: "bolder" }}>Products</span>
                </div>
                <Link to="/products">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'products' ? "#f5f3eb" : "#fff" }}>
                        <span>Products</span>
                    </div>
                </Link>
                <div style={{ padding: 8 }}>
                    <span style={{ fontSize: 18, fontWeight: "bolder" }}>Purchase</span>
                </div>
                <Link to="/products/cart/receive">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'order' ? "#f5f3eb" : "#fff"   }}>
                        <span>My Order</span>
                    </div>
                </Link> */}
                {/* <hr /> */}
                <div style={{ padding: 8 }}>
                    <span style={{ fontSize: 18, fontWeight: "bolder" }}>Genealogy</span>
                </div>
                <Link to="/matrix/genealogy">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'matrix' ? "#f5f3eb" : "#fff"  }}>
                        <span>Matrix Genealogy</span>
                    </div>
                </Link>
                <Link to="/personal/genealogy">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'personal' ? "#f5f3eb" : "#fff"  }}>
                        <span>Personal Genealogy</span>
                    </div>
                </Link>
                <hr />
                <Link to="/pickupcenter">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'puc' ? "#f5f3eb" : "#fff" }}>
                        <span>Pick-Up Center</span>
                    </div>
                </Link>
                {
                    props.raffle_program ? null
                    : 
                    <Link to="/raffle_program">
                        <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'raffle_program' ? "#f5f3eb" : "#fff" }} id='myBlinkingBackground'>
                            <span>Raffle Program</span>
                        </div>
                    </Link>
                }
                <hr />
                <Link to="/profile">
                    <div style={{ padding: 8, borderRadius: 10, color: "#495057", backgroundColor: props.tab === 'profile' ? "#f5f3eb" : "#fff" }}>
                        <span>Account Settings</span>
                    </div>
                </Link>
                <div onClick={()=>Logout()} style={{ padding: 8 }}>
                    <span>Logout</span>
                </div>
            </div>

        </Sidebar>
    )
}

export default LeftNavSwiper