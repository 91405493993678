import React, { useState } from 'react';
import ENV from '../ENV';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import swal from 'sweetalert';

const AdminNavbar = ({userInfo, refreshCodeList}) => {

    const [OpenDialog, setOpenDialog] = useState(false);
    const [account_id, setAccountId] = useState('');
    const [count, setCount] = useState(0);
    const [status, setStatus] = useState(0);
    const [entryPrice, setEntryPrice] = useState(0);
    const [codeGenerated, setNewCodeGenerated] = useState([]);

    // console.log(count)

    // Good morning sir, madali lang ba i-adjust ang entry package natin sa system?
    // Gawin nating ₱1,988 sir for one container then ang CV Nyan ay 1,750points x 50% = ₱875 ang DRB.

    const generateCode = async () => {

        let price = 0;
        let drb = 0;
        if(entryPrice == Number(3888)) {
            price = Number(3888)/Number(55)
            drb = Number(1750)/Number(55)
        } else if(entryPrice == Number(1988)) {
            price = Number(1988)/Number(55)
            drb = Number(875)/Number(55)
        }
        console.log(price.toFixed(5), drb.toFixed(5))

        // return;
        var formData = new FormData();
        formData.append("generate_code", 1);        
        formData.append("account_id", account_id); 
        formData.append("origin_price", entryPrice); 
        formData.append("entryPrice", price.toFixed(5));  
        formData.append("drb", drb.toFixed(5));  
        formData.append("count", count);  
        formData.append("status", status);
        formData.append("adminusername", localStorage.getItem('adminusername'));
        if (account_id!='' && count!=0) {    
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                if (res.data == 'success') {
                    swal({
                        // title: "Done!",
                        text: "Done!",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        setOpenDialog(false);
                        // refreshCodeList()
                        // setNewCodeGenerated(res.data)
                    });
                } else if (res.data == 'invalid_account_id') {
                    swal({
                        // title: "Done!",
                        text: "Invalid Account ID!",
                        icon: "error",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        // setOpenDialog(false);
                        // refreshCodeList()
                        // setNewCodeGenerated(res.data)
                    });
                }
            }).catch((err)=>{
    
            });
        }  
    }

    const hideShippingDialog = () => {
        // setSubmitted(false);
        setOpenDialog(false);
        setCount(0)
        setNewCodeGenerated([])
    };

    
    const ShippingDialogFooter = (
        <React.Fragment>
            <Button style={{width:100, fontSize:12}} label="Close" icon="pi pi-times" outlined onClick={hideShippingDialog} />
            {
                codeGenerated.length > 0 ?
                <Button style={{fontSize:12}} label="Create again" onClick={()=>setNewCodeGenerated([])} />
                :
                <Button style={{fontSize:12}} label="Generate New" icon="pi pi-check" onClick={generateCode} />
            }
        </React.Fragment>
    );



    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} /> */}
                <span className="brand-text font-weight-light">
                {
                    localStorage.getItem('adminusername') == 'master_admin' ?
                    'Master Administrator'
                    :null
                }
                {
                    localStorage.getItem('adminusername') == 'admin' ?
                    'Administrator'
                    :null
                }
                {
                    localStorage.getItem('adminusername') == 'account' ?
                    'Accounting'
                    :null
                }
                    
                </span>
            </a>
            {/* Sidebar */}
            <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition"><div className="os-resize-observer-host observed"><div className="os-resize-observer" style={{ left: 0, right: 'auto' }} /></div><div className="os-size-auto-observer observed" style={{ height: 'calc(100% + 1px)', float: 'left' }}><div className="os-resize-observer" /></div><div className="os-content-glue" style={{ margin: '0px -8px', width: 249, height: 528 }} /><div className="os-padding"><div className="os-viewport os-viewport-native-scrollbars-invisible" style={{ overflowY: 'scroll' }}><div className="os-content" style={{ padding: '0px 8px', height: '100%', width: '100%' }}>
                {/* Sidebar user panel (optional) */}
                {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="/profile" className="d-block">{userInfo?.fullname!=null ? userInfo?.fullname : 'Loading...'}</a>
                    </div>
                </div> */}
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>
                                    Dashboard
                                    <i className="right fas fa-angle-left" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <a href="./index.html" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Dashboard v1</p>
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <a href="/administrator/dashboard" className="nav-link">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                    <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                </svg> */}
                                <i className="far fa-circle nav-icon" />
                                <p>Dashboard</p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/administrator/members" className="nav-link">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                    <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                </svg> */}
                                <i className="far fa-circle nav-icon" />
                                <p>Member</p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="/administrator/pickupcenter" className="nav-link">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                    <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                </svg> */}
                                <i className="far fa-circle nav-icon" />
                                <p>Pick-Up Center</p>
                            </a>
                        </li>
                        {
                            localStorage.getItem('admin_id') === '2' || localStorage.getItem('admin_id') === '3' ?
                            <li className="nav-item">
                                <a href="/administrator/kyc/list/#" className="nav-link">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                        <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                    </svg> */}
                                    <i className="far fa-circle nav-icon" />
                                    <p>KYC Application</p>
                                </a>
                            </li>
                            : null
                        }
                        {
                            localStorage.getItem('admin_id') === '2' || localStorage.getItem('admin_id') === '3' ?
                                <li className="nav-item">
                                    <a href="/administrator/user_account" className="nav-link">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-microsoft nav-icon" viewBox="0 0 16 16">
                                        <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
                                    </svg> */}
                                        <i className="far fa-circle nav-icon" />
                                        <p>User Accounts</p>
                                    </a>
                                </li>
                                : null
                        }
                        <li className="nav-item menu-is-opening menu-open">
                            <a href="#" className="nav-link">
                                <p>
                                Activation Code
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    <a href="/administrator/code" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Unpaid Code</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/code/used" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Paid Code</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <Dialog visible={OpenDialog} style={{ width: '30rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={
                                        codeGenerated.length > 0 ?
                                            <span style={{color:"green"}}>
                                                Successfully Code Generated
                                            </span>
                                            :
                                            <span>
                                                Generate New Code
                                            </span>
                                        } modal className="p-fluid" footer={ShippingDialogFooter} onHide={hideShippingDialog}>
                                        {
                                            codeGenerated.length > 0 ?
                                            <>
                                            <div style={{display:"flex", flexDirection:"column", gap:1}}>
                                                <span>Count: {codeGenerated.length}</span>
                                                {
                                                    codeGenerated.map((item, key)=>{
                                                        return (
                                                            <div style={{padding:5, backgroundColor:"#ddd"}}>{item.code}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            </>
                                            :
                                            <>
                                                <label>Select Quantity</label>
                                                <div>
                                                    <div style={{padding:5}}>
                                                        <span>Enter Account ID</span>
                                                        <div>
                                                            <input onChange={(e)=>setAccountId(e.target.value)} placeholder='ID' style={{padding:5, height:40, width:"100%", borderRadius:5, border:"1px solid #ddd"}} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{padding:5}}>
                                                        <select onChange={(e)=>setEntryPrice(e.target.value)} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd"}}>
                                                            <option value={""}>Select Entry</option>
                                                            <option value={"3888"}>3888</option>
                                                            <option value={"1988"}>1988</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <div style={{padding:5}}>
                                                        <select onChange={(e)=>setStatus(e.target.value)} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd"}}>
                                                            <option value={"0"}>Unpaid</option>
                                                            <option value={"1"}>Paid</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div>
                                                    <div style={{padding:5}}>
                                                        <select onChange={(e)=>setCount(e.target.value)} style={{width:"100%", height:40, borderRadius:5, border:"1px solid #ddd"}}>
                                                            <option value={"0"} selected disabled>Select</option>
                                                            <option value={"1"}>1</option>
                                                            <option value={"2"}>2</option>
                                                            <option value={"3"}>3</option>
                                                            <option value={"4"}>4</option>
                                                            <option value={"5"}>5</option>
                                                            <option value={"10"}>10</option>
                                                            <option value={"15"}>15</option>
                                                            <option value={"20"}>20</option>
                                                            <option value={"50"}>50</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        
                                    </Dialog>
                                {/* href="/administrator/code/generate" */}
                                    <a onClick={()=>setOpenDialog(true)} className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Generate New Code</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open" style={{display:"block"}}>
                            <a href="#" className="nav-link">
                                <p>
                                Payins
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                            <li className="nav-item">
                                    <a href="/administrator/payin/summary" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Pay-in Summary</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open" style={{display:"none"}}>
                            <a href="#" className="nav-link">
                                <p>
                                POS
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    <a href="/administrator/payin/summary" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Sales</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/payin/summary" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Summary</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open" style={{display:"none"}}>
                            <a href="#" className="nav-link">
                                <p>
                                Pickup Center
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Main Office</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open" style={{display:"none"}}>
                            <a href="#" className="nav-link">
                                <p>
                                Manage Product
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                            <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Product</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Stock</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open" style={{display:"none"}}>
                            <a href="#" className="nav-link">
                                <p>
                                Online Shop
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    <a href="/administrator/order/new" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>New Order</p>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a href="/administrator/order/pending" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Pending Order</p>
                                    </a>
                                </li> */}
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Shipped Order</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Completed Order</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Cancelled/Declined Order</p>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Voucher</p>
                                    </a>
                                </li> */}
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open">
                            <a href="#" className="nav-link">
                                <p>
                                Payout
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    <a href="/administrator/payout/request" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Payout Request</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/payout/history" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Payout History</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/payout/report" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Payout/Balance Reports</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open" style={{display:"none"}}>
                            <a href="#" className="nav-link">
                                <p>
                                Transactions
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    <a href="/administrator/transactions" className="nav-link">
                                        <p>
                                            Transactions
                                        </p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/transactions/payin" className="nav-link">
                                        <p>
                                            Pay-in History
                                        </p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/transactions/matrixrefill" className="nav-link">
                                        <p>
                                            Matrix Refill History
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link">
                                <p>
                                Generator
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'none' }}>
                                <li className="nav-item">
                                    <a href="/administrator/code" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Barcode</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/administrator/code/used" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>QRCode</p>
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                        <li className="nav-item menu-is-opening menu-open" style={{display:"none"}}>
                            <a href="#" className="nav-link">
                                <p>
                                Report
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    {/* /administrator/report/ */}
                                    <a href="/administrator/report/entry" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Entry</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    {/* /administrator/report/ */}
                                    <a href="/administrator/report/sales" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Sales</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    {/* /administrator/report/ */}
                                    <a href="/administrator/report/payout" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Payout</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    {/* /administrator/report/ */}
                                    <a href="#" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Expenses</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item menu-is-opening menu-open">
                            <a href="#" className="nav-link">
                                <p>
                                    Logistics
                                    <i className="fas fa-angle-left right" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview" style={{ display: 'block' }}>
                                <li className="nav-item">
                                    {/* inventory */}
                                    <a href="/administrator/logistics/production" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Production</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    {/* inventory */}
                                    <a href="/administrator/logistics/warehouse" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Warehouse</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    {/* inventory */}
                                    <a href="/administrator/logistics/distributors" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Distributors</p>
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a href="/administrator/inventory/puc" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Pick-up Center</p>
                                    </a>
                                </li> */}
                            </ul>
                        </li>
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div></div></div><div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ width: '100%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden"><div className="os-scrollbar-track"><div className="os-scrollbar-handle" style={{ height: '38.9257%', transform: 'translate(0px, 0px)' }} /></div></div><div className="os-scrollbar-corner" /></div>
            {/* /.sidebar */}
        </aside>

    );
}

export default AdminNavbar;
